import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.10.0_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.2_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}