import payload_plugin_GPnaAXCB8n from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.5.0_firebase@10.13.1_vuefire@3.2.0/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_xCuFMtYFCI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.10.0_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_22lphSGSHK from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.10.0_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Rm87Qz6m3t from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.10.0_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_g5ivNEHltQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.17_vite@5.4.5_vue@3.5.5/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_DTrrbJvvqK from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.10.0_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_EwfW1FEUec from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.10.0_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bg8DjuhX4O from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.10.0_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_irKmx6uT5q from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.10.0_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _64nuxt_scripts_D34jXE4xNh from "/opt/buildhome/repo/.nuxt/modules/@nuxt-scripts.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_yXkIOGvOmy from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_eslint@9.10.0_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_EmtUANWF8B from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.5.0_firebase@10.13.1_vuefire@3.2.0/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/opt/buildhome/repo/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/opt/buildhome/repo/.nuxt/vuefire-plugin.mjs";
import titles_gjxcARM1sH from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.12.0_vite@5.4.5_vue@3.5.5/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_vWXoYmlH5X from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_vite@5.4.5_vue@3.5.5/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_BnljtpagWm from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_vite@5.4.5_vue@3.5.5/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import slideovers_cCtnCBtGWH from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_KhBN2hMArK from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_tsMeSazdCZ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_4lwVhHx62L from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_DHthasS1Hk from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.5.1_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_IufDCau8So from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.2_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_77QxWH6hsK from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.2_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_4NNADfdxOz from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.2_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import paddleJS_client_6OSkSWlVfm from "/opt/buildhome/repo/plugins/paddleJS.client.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/plugins/sentry.client.ts";
import defaults_1LgiFYHKRU from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.12.0_vite@5.4.5_vue@3.5.5/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  payload_plugin_GPnaAXCB8n,
  revive_payload_client_xCuFMtYFCI,
  unhead_22lphSGSHK,
  router_Rm87Qz6m3t,
  _0_siteConfig_g5ivNEHltQ,
  payload_client_DTrrbJvvqK,
  navigation_repaint_client_EwfW1FEUec,
  check_outdated_build_client_bg8DjuhX4O,
  chunk_reload_client_irKmx6uT5q,
  _64nuxt_scripts_D34jXE4xNh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_yXkIOGvOmy,
  plugin_client_EmtUANWF8B,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  titles_gjxcARM1sH,
  siteConfig_vWXoYmlH5X,
  inferSeoMetaPlugin_BnljtpagWm,
  slideovers_cCtnCBtGWH,
  modals_KhBN2hMArK,
  colors_tsMeSazdCZ,
  plugin_client_4lwVhHx62L,
  plugin_DHthasS1Hk,
  scrollbars_client_IufDCau8So,
  presets_77QxWH6hsK,
  variables_4NNADfdxOz,
  paddleJS_client_6OSkSWlVfm,
  sentry_client_shVUlIjFLk,
  defaults_1LgiFYHKRU
]